var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-term" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, model: _vm.searchInfo }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索渠道名称" },
                    model: {
                      value: _vm.searchInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "name", $$v)
                      },
                      expression: "searchInfo.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "推广形式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择推广形式" },
                      model: {
                        value: _vm.searchInfo.mode,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "mode", $$v)
                        },
                        expression: "searchInfo.mode"
                      }
                    },
                    _vm._l(_vm.modeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择链接类型" },
                      model: {
                        value: _vm.searchInfo.link_type,
                        callback: function($$v) {
                          _vm.$set(_vm.searchInfo, "link_type", $$v)
                        },
                        expression: "searchInfo.link_type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否分配" } },
                [
                  _c("el-switch", {
                    attrs: { "active-text": "是", "inactive-text": "否" },
                    model: {
                      value: _vm.searchInfo.is_distribute,
                      callback: function($$v) {
                        _vm.$set(_vm.searchInfo, "is_distribute", $$v)
                      },
                      expression: "searchInfo.is_distribute"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                    [_vm._v("查询")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openDialog }
                    },
                    [_vm._v("新建")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success" },
                      on: { click: _vm.showUploadForm }
                    },
                    [_vm._v("订单上传")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            stripe: "",
            "tooltip-effect": "dark"
          }
        },
        [
          _c("el-table-column", {
            attrs: { label: "渠道名称", prop: "name" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.name))]),
                    scope.row.is_distributed
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "success" }
                          },
                          [_vm._v("已分配")]
                        )
                      : _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "info" }
                          },
                          [_vm._v("未分配")]
                        )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "备注信息", prop: "remark" }
          }),
          _c("el-table-column", {
            attrs: { label: "推广形式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.mode === 1
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("基础红包页")
                        ])
                      : scope.row.mode === 2
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("翻卡牌")
                        ])
                      : scope.row.mode === 3
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("视频页")
                        ])
                      : scope.row.mode === 4
                      ? _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("储蓄罐")
                        ])
                      : _vm._e(),
                    scope.row.link_type === 1
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "warning" }
                          },
                          [_vm._v("H5")]
                        )
                      : scope.row.link_type === 2
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "success" }
                          },
                          [_vm._v("微信小程序")]
                        )
                      : scope.row.link_type === 3
                      ? _c(
                          "el-tag",
                          {
                            staticStyle: { "margin-left": "10px" },
                            attrs: { type: "primary" }
                          },
                          [_vm._v("支付宝小程序")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "推广信息", prop: "link" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-dialog",
                      {
                        attrs: {
                          visible: _vm.detailDialogVisible,
                          title: "推广信息"
                        },
                        on: {
                          "update:visible": function($event) {
                            _vm.detailDialogVisible = $event
                          }
                        }
                      },
                      [
                        _c("el-form", [
                          _vm.detailDialogData.link_type == 1
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "长链接" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          autocomplete: "off",
                                          type: "textarea",
                                          rows: 2
                                        },
                                        model: {
                                          value: _vm.detailDialogData.link,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.detailDialogData,
                                              "link",
                                              $$v
                                            )
                                          },
                                          expression: "detailDialogData.link"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm.detailDialogData.shortLink
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "短链接" } },
                                        [
                                          _c("el-input", {
                                            attrs: { autocomplete: "off" },
                                            model: {
                                              value:
                                                _vm.detailDialogData.shortLink,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.detailDialogData,
                                                  "shortLink",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailDialogData.shortLink"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.createShortUrl(
                                                _vm.detailDialogData.link
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("生成短链接")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _vm.detailDialogData.link_type == 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "小程序APPID" } },
                                        [
                                          _c("el-input", {
                                            attrs: { autocomplete: "off" },
                                            model: {
                                              value:
                                                _vm.detailDialogData.program_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.detailDialogData,
                                                  "program_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailDialogData.program_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.detailDialogData.link_type == 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "小程序原始ID" } },
                                        [
                                          _c("el-input", {
                                            attrs: { autocomplete: "off" },
                                            model: {
                                              value:
                                                _vm.detailDialogData
                                                  .ori_program_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.detailDialogData,
                                                  "ori_program_id",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailDialogData.ori_program_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.detailDialogData.link_type == 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "小程序路径" } },
                                        [
                                          _c("el-input", {
                                            attrs: { autocomplete: "off" },
                                            model: {
                                              value: _vm.detailDialogData.link,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.detailDialogData,
                                                  "link",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "detailDialogData.link"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "DeepLink" } },
                                    [
                                      _c("el-input", {
                                        attrs: { autocomplete: "off" },
                                        model: {
                                          value: _vm.detailDialogData.deeplink,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.detailDialogData,
                                              "deeplink",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "detailDialogData.deeplink"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.showLinks(scope.row)
                          }
                        }
                      },
                      [_vm._v("查看")]
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "关联用户", prop: "member_id" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.member_id
                      ? _c("span", [_vm._v(_vm._s(scope.row.member_id))])
                      : _c("span", [_vm._v("未关联")])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "分成比" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.commission_rate) + "% ")
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticClass: "table-button",
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-edit"
                        },
                        on: {
                          click: function($event) {
                            return _vm.updateElmOutLinks(scope.row)
                          }
                        }
                      },
                      [_vm._v("编辑")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("el-pagination", {
        style: { float: "right", padding: "20px" },
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.pageSize,
          "page-sizes": [10, 30, 50, 100],
          total: _vm.total,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange
        }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            visible: _vm.dialogFormVisible,
            title: "新建",
            width: "60%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              attrs: {
                model: _vm.formData,
                "label-position": "right",
                "label-width": "150px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "渠道名称:", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入渠道名称"
                            },
                            model: {
                              value: _vm.formData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "name", $$v)
                              },
                              expression: "formData.name"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广形式:", prop: "mode" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.formData.mode,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "mode", $$v)
                                },
                                expression: "formData.mode"
                              }
                            },
                            _vm._l(_vm.modeOptions, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "链接类型:", prop: "link_type" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: {
                        change: function($event) {
                          return _vm.autoInput()
                        }
                      },
                      model: {
                        value: _vm.formData.link_type,
                        callback: function($$v) {
                          _vm.$set(_vm.formData, "link_type", $$v)
                        },
                        expression: "formData.link_type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.formData.link_type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "推广链接:", prop: "link" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入推广链接",
                          type: "textarea",
                          rows: 2
                        },
                        model: {
                          value: _vm.formData.link,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "link", $$v)
                          },
                          expression: "formData.link"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "小程序路径:", prop: "link" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入小程序路径",
                          type: "textarea",
                          rows: 2
                        },
                        model: {
                          value: _vm.formData.link,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "link", $$v)
                          },
                          expression: "formData.link"
                        }
                      })
                    ],
                    1
                  ),
              _vm.formData.link_type == 2 || _vm.formData.link_type == 3
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "小程序AppID:" } },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "请输入小程序APPID"
                            },
                            model: {
                              value: _vm.formData.program_id,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "program_id", $$v)
                              },
                              expression: "formData.program_id"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.formData.link_type == 2
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "小程序原始ID:" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入小程序原始ID"
                                },
                                model: {
                                  value: _vm.formData.ori_program_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "ori_program_id",
                                      $$v
                                    )
                                  },
                                  expression: "formData.ori_program_id"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "DeepLink:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入DEEPLINK" },
                    on: {
                      blur: function($event) {
                        return _vm.loadPath()
                      }
                    },
                    model: {
                      value: _vm.formData.deeplink,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "deeplink", $$v)
                      },
                      expression: "formData.deeplink"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否分配:" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                      "active-text": "是",
                      "inactive-text": "否",
                      clearable: ""
                    },
                    model: {
                      value: _vm.formData.is_distributed,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "is_distributed", $$v)
                      },
                      expression: "formData.is_distributed"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户ID:" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入用户ID" },
                    model: {
                      value: _vm.formData.member_id,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "member_id", _vm._n($$v))
                      },
                      expression: "formData.member_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分成比:", prop: "commission_rate" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入分成比" },
                    model: {
                      value: _vm.formData.commission_rate,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "commission_rate", _vm._n($$v))
                      },
                      expression: "formData.commission_rate"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注信息:" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      clearable: "",
                      placeholder: "请输入渠道备注，自己可以区分渠道"
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.enterDialog } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeStockDialog,
            visible: _vm.dialogSkuStockVisible,
            title: "订单Excel上传"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogSkuStockVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "uploadForm",
              attrs: {
                model: _vm.orderInfo,
                "label-position": "right",
                "label-width": "100px",
                rules: _vm.uploadFormRules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文件上传", prop: "filePath" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action: _vm.imageAction,
                        "before-upload": _vm.imageBeforeUpload,
                        "on-success": _vm.handleFileSuccess,
                        "file-list": _vm.fileList
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.closeStockDialog } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading.fullscreen.lock",
                      value: _vm.uploading,
                      expression: "uploading",
                      modifiers: { fullscreen: true, lock: true }
                    }
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.upload }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }